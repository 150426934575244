<template>
	<div>
		<div
			class="
				content-top-bar
				d-flex
				justify-content-end
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<a class="text-danger fw-bold" href="#" @click.prevent="logout">
				Sign Out Completely
			</a>
		</div>

		<div>
			<template v-for="cluster in clusters" :key="cluster.name">
				<template
					v-for="organisation in cluster.organisations"
					:key="organisation.organisationName"
				>
					<div
						v-if="organisation.organisationName != 'Superusers'"
						class="roles-div"
					>
						<div class="bg-div w-100 position-relative">
							<img
								:src="
									organisation.coverImage
										? organisation.coverImage
										: '/img/institution.jpg'
								"
								alt=""
							/>
							<div class="position-absolute bg-gradient bottom-0" />
							<h1 class="position-absolute bottom-0 p-3">
								{{ organisation.organisationName }}
							</h1>
						</div>
						<div class="row m-0 d-flex position-relative z-index-1">
							<div
								v-if="organisation.users.student"
								:class="{
									'col-6 pe-025':
										(organisation.users.teacher ? 1 : 0) +
											(organisation.users.superAdmin ? 1 : 0) ==
										1,
									'col-4 pe-025':
										(organisation.users.teacher ? 1 : 0) +
											(organisation.users.superAdmin ? 1 : 0) ==
										2,
								}"
								class="button-div p-0"
							>
								<button
									class="btn btn-primary no-focus-ring w-100 h-100"
									:disabled="
										organisation.users.student.isSuspended ||
										(organisation.users.student.meta === null &&
											organisation.users.student.auth === null)
									"
									@click.prevent="
										processClick(cluster, organisation, 'student')
									"
								>
									Sign In as Student
									<span
										v-if="organisation.users.student.isSuspended"
										class="disabled-text-message"
									>
										Your account has been suspended
									</span>
								</button>
							</div>
							<div
								v-if="organisation.users.superAdmin"
								:class="{
									'col-6 pe-025':
										(organisation.users.teacher ? 1 : 0) +
											(organisation.users.student ? 1 : 0) ==
										1,
									'col-4 pe-025':
										(organisation.users.teacher ? 1 : 0) +
											(organisation.users.student ? 1 : 0) ==
										2,
								}"
								class="button-div p-0"
								@click.prevent="
									processClick(cluster, organisation, 'superAdmin')
								"
							>
								<button
									class="btn btn-success no-focus-ring w-100 h-100"
									:disabled="organisation.users.superAdmin.isSuspended"
								>
									Sign In as {{ organisation.users.superAdmin.role }}
									<span
										v-if="organisation.users.superAdmin.isSuspended"
										class="disabled-text-message"
									>
										Your account has been suspended
									</span>
								</button>
							</div>
							<div
								v-if="organisation.users.teacher"
								:class="{
									'col-6 pe-025':
										(organisation.users.superAdmin ? 1 : 0) +
											(organisation.users.student ? 1 : 0) ==
										1,
									'col-4 pe-025':
										(organisation.users.superAdmin ? 1 : 0) +
											(organisation.users.student ? 1 : 0) ==
										2,
								}"
								class="button-div p-0"
							>
								<button
									class="btn btn-orange no-focus-ring w-100 h-100"
									:disabled="organisation.users.teacher.isSuspended"
									@click.prevent="
										processClick(cluster, organisation, 'teacher')
									"
								>
									Sign In as {{ organisation.users.teacher.role }}
									<span
										v-if="organisation.users.teacher.isSuspended"
										class="disabled-text-message"
									>
										Your account has been suspended
									</span>
								</button>
							</div>
						</div>
					</div>
					<div v-else class="roles-div">
						<div class="bg-div w-100 position-relative">
							<img src="/img/World-Map.png" alt="" />
							<div class="position-absolute bg-gradient bottom-0" />
							<h1 class="position-absolute bottom-0 p-3">CognaLearn Pte Ltd</h1>
						</div>
						<div class="row m-0 position-relative z-index-1">
							<button
								class="btn btn-pink no-focus-ring"
								@click.prevent="
									processClick(cluster, organisation, 'superuser')
								"
							>
								Sign In as Superuser ({{ cluster.name }})
							</button>
						</div>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
import { toRefs, inject } from "vue";
export default {
	props: {
		clusters: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	emits: ["choose", "logout", "suspended"],
	setup(props, context) {
		const helpers = inject("helpers");

		const { clusters } = toRefs(props);
		const processClick = (cluster, organisation, role = "") => {
			if (!organisation.users[role].isSuspended) {
				let ret = {
					clusterUrl: cluster.url,
					clusterName: cluster.name,
					organisationName: organisation.organisationName,
					...organisation.users[role],
				};
				context.emit("choose", ret);
			} else {
				context.emit("suspended");
			}
		};
		const logout = () => {
			context.emit("logout");
		};
		return {
			helpers,
			processClick,
			logout,
			clusters,
		};
	},
};
</script>
<style lang="scss" scoped>
.roles-div {
	div.bg-div {
		height: 300px;

		div:nth-child(2) {
			width: 100%;
			height: 300px;

			&.bg-gradient {
				background-image: linear-gradient(
					180deg,
					rgba(30, 64, 175, 0) 0%,
					#1e40af 100%
				) !important;
			}
		}

		h1 {
			font-weight: bold;
			color: #fff;
			font-size: 28px;
			margin: 0;
		}
	}
}
</style>
