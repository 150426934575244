<template>
	<div
		class="
			content-top-bar
			d-flex
			justify-content-between
			align-items-center
			bg-white
		"
		:class="{
			'top-bar-fixed p-3': helpers.layout.isMobile(),
			'position-sticky p-4': !helpers.layout.isMobile(),
		}"
	>
		<a
			v-if="publishedArrow"
			class="text-dark d-flex"
			@click.prevent="processBackButton"
		>
			<span class="material-icons-outlined" aria-label="back">
				arrow_back
			</span>
		</a>

		<div
			v-if="step > 0"
			data-bs-toggle="modal"
			data-bs-target="#compatibility"
			class="text-primary fw-bold"
			@click="skip"
		>
			Skip this step
		</div>
	</div>
	<kr-form
		ref="form"
		v-slot="{ meta, rules, data, isSubmitting }"
		:options="formOptions"
		class="needs-validation"
		autocomplete="false"
		novalidate
	>
		<div
			:class="{
				'pb-104': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<div
				class="mx-auto pt-40"
				:class="{
					'w-100': helpers.layout.isMobile(),
					'w-50 min-h-100': !helpers.layout.isMobile(),
				}"
			>
				<div
					v-if="step > -1"
					:class="{
						'px-3': helpers.layout.isMobile(),
					}"
				>
					<h2 v-if="step == 1" class="fw-bold">Pick Your Section</h2>
					<h2 v-else-if="step == 2" class="fw-bold">Pick Your Team</h2>

					<div v-if="step == 2" class="course-info">
						<h2>
							{{ course.name }}
							{{ course.code ? `( ${course.code} )` : "" }}
						</h2>
						<label v-if="course.description" class="form-label">
							Description
						</label>
						<p v-if="course.description">
							{{ course.description }}
						</p>
						<label v-if="course.organisationName" class="form-label">
							School
						</label>
						<p v-if="course.organisationName">
							{{ course.organisationName }}
						</p>
						<label v-if="course.courseOwner" class="form-label">
							Instructor
						</label>
						<p v-if="course.courseOwner">{{ course.courseOwner }}</p>
					</div>
					<div v-show="step == 0">
						<h2>Enrolled Course(s) ({{ enrolledCourses.length }})</h2>
						<template v-if="enrolledCourses.length == 0">
							No enrolled course
						</template>
						<template v-else>
							<div class="accordion-item">
								<h3 id="enrolledCoursesHeading" class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#enrollCoursesCollapse"
										aria-expanded="false"
										aria-controls="enrollCoursesCollapse"
									>
										<b>Show All Course(s)</b>
									</button>
								</h3>
								<div
									id="enrollCoursesCollapse"
									class="accordion-collapse collapse"
									aria-labelledby="enrolledCoursesHeading"
								>
									<div class="accordion-body">
										<ul v-for="c in enrolledCourses" :key="c">
											<li class="text-black">
												{{ c }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</template>

						<button
							class="w-100 btn btn-primary mt-3"
							:disabled="enrolledCourses.length === 0"
							@click="done"
						>
							Continue
						</button>
					</div>
					<hr v-show="step == 0" class="hr hr-blurry" />
					<div v-show="step == 0" class="form-check">
						<h2>Enroll Into New Course(s) ({{ courses.length }})</h2>
						<template v-if="searchedCourses.courses.length == 0">
							No course found using search term
						</template>
						<div v-else class="accordion-item">
							<h3 id="enrollCourseHeading" class="accordion-header">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#enrollCourseCollapse"
									aria-expanded="false"
									aria-controls="enrollCourseCollapse"
								>
									<b>Show All Course(s)</b>
								</button>
							</h3>
							<div
								id="enrollCourseCollapse"
								class="accordion-collapse collapse"
								aria-labelledby="enrollCourseHeading"
							>
								<div class="accordion-body">
									<div class="w-100 position-relative mb-2 height48">
										<input
											v-model="searchedCourses.q"
											type="text"
											class="form-control position-inherit"
											placeholder="Search course(s)"
											aria-label="search course"
											aria-describedby="search-courses"
											@keyup="searchCourses"
										/>
										<button
											class="btn position-absolute top--3px end-0"
											aria-label="search icon"
										>
											<span
												class="material-symbols-outlined p-0 border-0"
												aria-hidden="true"
											>
												search
											</span>
										</button>
									</div>

									<template
										v-for="c in searchedCourses.courses"
										:key="course.uuid"
									>
										<label>
											<input
												v-model="course"
												type="radio"
												class="form-check-input"
												name="selectSection"
												:value="c"
											/>
											<span>
												{{ c.name }}
												<template v-if="c.enrolled">(Enrolled)</template>
											</span>
										</label>
									</template>
								</div>
							</div>
						</div>

						<button
							class="w-100 btn btn-primary mt-3"
							:disabled="course === false"
							data-bs-toggle="modal"
							data-bs-target="#courseConfirmation"
						>
							<template v-if="course === false"> Pick a Course </template>
							<template v-else> Continue </template>
							<!-- Join Course -->
						</button>
					</div>
					<div v-show="step == 1" class="form-check">
						<template v-for="(section, idx) in sections" :key="section.uuid">
							<kr-form-i
								v-slot="{ field }"
								v-model="data.model.sectionIdx"
								name="sectionIdx"
								:rules="rules('sectionIdx')"
							>
								<label>
									<input
										type="radio"
										class="form-check-input"
										name="selectSection"
										v-bind="field"
										:value="idx"
									/>
									<span> {{ section.name }}</span>
								</label>
							</kr-form-i>
						</template>
					</div>

					<div v-if="step == 2" id="teamAccordion" class="accordion">
						<div
							v-for="(team, idx) in sections[form.values.sectionIdx].teams"
							:key="team.uuid"
							class="accordion-item"
						>
							<div
								:id="`heading${idx}`"
								class="accordion-header form-check collapsed"
								type="button"
								data-bs-toggle="collapse"
								:data-bs-target="`#collapse${idx}`"
								:aria-controls="`collapse${idx}`"
							>
								<kr-form-i
									v-slot="{ field, errors }"
									v-model="data.model.teamIdx"
									name="teamIdx"
									:rules="rules('teamIdx')"
								>
									<label>
										<input
											type="radio"
											class="form-check-input"
											v-bind="field"
											:value="idx"
										/>
										<span> {{ team.name }} </span>
									</label>
								</kr-form-i>
							</div>
							<div
								:id="'collapse' + idx"
								class="accordion-collapse collapse"
								:aria-labelledby="'heading' + idx"
								data-bs-parent="#teamAccordion"
							>
								<div class="accordion-body">
									<template v-if="team.members.length == 0">
										<div class="d-flex align-items-center">No Teammates</div>
									</template>
									<template
										v-else
										v-for="member in team.members"
										:key="member.name"
									>
										<div class="d-flex align-items-center">
											<div class="image me-3">
												<!-- <img src="/img/profile_image/Vivian B.png" /> -->
												<div class="initials-img">
													{{ member.name.charAt(0) }}
												</div>
											</div>
											{{ member.name }}
										</div>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center pb-40" v-else>
					<span
						class="spinner-border spinner-border-sm me-8px"
						role="status"
						aria-hidden="true"
					></span>
					Loading...
				</div>
			</div>
			<div
				:class="{
					'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
					'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
				}"
			>
				<div
					v-if="helpers.layout.isMobile()"
					class="custom-btn-gradient bg-gradient"
				/>
				<button
					v-if="form && step == 1"
					class="w-100 btn btn-primary"
					:disabled="form.values.sectionIdx == -1"
					@click="step = 2"
				>
					<template v-if="form.values.sectionIdx == -1">
						Pick a Section
					</template>
					<template v-else> Continue </template>
					<!-- Join Section -->
				</button>
				<button
					v-if="form && step == 2"
					class="w-100 btn btn-primary"
					:disabled="form.values.teamIdx == -1 || isSubmitting"
					@click="joinTeam"
				>
					<template v-if="isSubmitting">
						<span
							class="spinner-border spinner-border-sm me-8px"
							role="status"
							aria-hidden="true"
						></span>
						Loading...
					</template>
					<template v-else> Join Team </template>
				</button>
			</div>
		</div>
	</kr-form>

	<div
		id="courseConfirmation"
		class="modal warning-modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		tabindex="-1"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
		aria-labelledby="courseConfirmation-title"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'modal-dialog-flex-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<h2 id="courseConfirmation-title" class="modal-title">
						Enrolling Confirmation
					</h2>

					<button
						type="button"
						class="btn close"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-symbols-outlined" aria-hidden="true">
							close
						</span>
					</button>
				</div>
				<div class="modal-body text-center">
					<img src="/img/warning.png" class="warning-icon" alt="warning icon" />
					<h3 class="mt-4">Enrolling Into A New Course</h3>
					<h3>{{ course.name }}</h3>
					<p class="mb-0">You are about to enroll to the course,</p>
					<p>please make sure you are in the right course.</p>
				</div>
				<div class="modal-footer">
					<button
						class="btn btn-outline-dark"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						Cancel
					</button>
					<button
						class="btn btn-success"
						data-bs-dismiss="modal"
						@click="retrieveSections"
					>
						Confirm
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref, reactive, toRefs, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

export default {
	props: {
		courses: {
			type: Array,
			default: function () {
				return [];
			},
		},
		enrolledCourses: {
			type: Array,
			default: function () {
				return [];
			},
		},
		credentials: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	emits: ["back", "done"],
	setup(props, context) {
		const helpers = inject("helpers");
		const $api = inject("$api");
		const form = ref(null);
		const step = ref(-1);
		const { courses, enrolledCourses, credentials } = toRefs(props);
		var course = ref(false);
		const sections = ref([]);
		const formOptions = ref({
			fields: {
				sectionIdx: { value: -1, rules: "" },
				teamIdx: { value: -1, rules: "" },
			},
		});
		const searchedCourses = reactive({ q: "", courses: [] });
		const route = useRoute();
		const hideArrow = ref(false);

		const retrieveSections = () => {
			$api.auth
				.getSections(course.value.uuid, credentials.value.data.api_url, {
					Authorization: `Bearer ${credentials.value.data.token}`,
				})
				.then(function (response) {
					if (response.data.data.selfAssignment) {
						sections.value = response.data.data.sections;
						step.value = 1;
					} else {
						context.emit("done");
					}
				})
				.catch(function (error) {});
		};
		const joinTeam = () => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.courseSelfAssign(
						{
							courseUuid: course.value.uuid,
							sectionUuid: sections.value[values.sectionIdx].uuid,
							teamUuid:
								sections.value[values.sectionIdx].teams[values.teamIdx].uuid,
						},
						credentials.value.data.api_url,
						{ Authorization: `Bearer ${credentials.value.data.token}` }
					)
					.then(function (response) {
						context.emit("done");
					})
					.catch(function (error) {});
			})();
		};
		const skip = () => {
			return $api.auth
				.courseSkipSelfAssign(
					{
						courseUuid: course.value.uuid,
					},
					credentials.value.data.api_url,
					{ Authorization: `Bearer ${credentials.value.data.token}` }
				)
				.then(function (response) {
					context.emit("done");
				})
				.catch(function (error) {});
		};
		const processBackButton = () => {
			if (step.value == 2) {
				form.value.setFieldValue("teamIdx", -1);
				step.value = 1;
			} else if (step.value == 1) {
				if (courses.value.length == 1) {
					context.emit("back");
				} else {
					step.value = 0;
				}
			} else if (step.value == 0) {
				context.emit("back");
			}
		};
		const done = () => {
			context.emit("done");
		};
		const searchCourses = () => {
			searchedCourses.courses = [];
			if (searchedCourses.q) {
				for (var i = 0; i < courses.value.length; i++) {
					let course = courses.value[i];
					let haystack = (course.name + course.code + "").toLowerCase();
					let doesContain = haystack.indexOf(searchedCourses.q.toLowerCase());
					if (doesContain != -1) {
						searchedCourses.courses.push(courses.value[i]);
					}
				}
			} else {
				searchedCourses.courses = courses.value;
			}
		};

		const publishedArrow = computed(() => {
			if (route.name == "auth.lti" && step.value == 0) {
				return false;
			} else if (step.value > -1) {
				return true;
			}
			return false;
		});

		onMounted(() => {
			if (courses.value.length == 1 && !credentials.value.lti1p3) {
				course.value = courses.value[0];
				retrieveSections(course.uuid);
				//step.value = 1;
			} else {
				step.value = 0;
				searchCourses();
			}
			if (sections.value.length == 1) {
				form.value.setFieldValue("sectionIdx", 0);
				step.value = 2;
			}
		});
		return {
			helpers,
			form,
			step,
			courses,
			course, //selected course
			sections,
			formOptions,
			joinTeam,
			skip,
			done,
			processBackButton,
			retrieveSections,
			searchCourses,
			searchedCourses,
			enrolledCourses,
			publishedArrow,
		};
	},
};
</script>

<style lang="scss" scoped>
.form-check {
	padding-left: 0;

	label {
		display: block !important;
		width: 100%;
		// cursor: pointer;
		position: relative;
		margin-top: 16px;
	}

	input {
		position: absolute;
		left: 16px;
		top: 16px;

		&.position-inherit {
			position: inherit;
			top: 0;
			left: 0;
		}
	}

	.form-check-input {
		width: 24px;
		height: 24px;
		margin-top: 0;
		margin-left: 0;
		border: 1px solid rgba(60, 60, 67, 0.1);
		box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
		margin-right: 8px;

		// &:hover {
		// 	cursor: pointer;
		// }

		&[type="image"] {
			border: none;
		}

		&:disabled {
			background: rgba(60, 60, 67, 0.24);
			opacity: 1;
			box-shadow: none;
			border: none;
		}

		&:checked {
			background-color: #fff;
			border: 1px solid rgba(60, 60, 67, 0.1);

			&[type="radio"] {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23007aff'/%3e%3c/svg%3e");
			}

			& ~ .form-check-label {
				color: #3c3c43;
			}

			&:disabled[type="radio"] {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%233C3C438F'/%3e%3c/svg%3e");
			}
		}

		&:focus {
			box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
		}
	}

	span {
		display: block;
		padding: 16px 16px 16px 48px;
		border: 1px solid rgba(60, 60, 67, 0.16);
		border-radius: 8px;
		transition: all 0.25s linear;
		font-size: 16px;
		line-height: 1.5;
	}

	input:checked + span {
		border: 1px solid #007aff;
	}

	input:disabled + span {
		background: #efefef;
		color: rgba(60, 60, 67, 0.56);
		border: 0;
	}
}

#teamAccordion {
	.accordion-item {
		border: none;
	}

	.form-check {
		margin-bottom: 0;
	}

	input:checked + span {
		border: 1px solid #007aff;
		// border-bottom: 0;
		// border-bottom-left-radius: 0;
		// border-bottom-right-radius: 0;
		background: #dbeafe;
	}

	.accordion-collapse {
		position: relative;
		top: -10px;
		background: rgb(255, 255, 255);
		transition: all 0.25s linear;
	}

	.accordion-body {
		border: 1px solid #007aff;
		border-top: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		& > div {
			margin-top: 14px;

			&:first-child {
				margin-top: 0;
			}
		}
	}
	.accordion-header :not(.collapsed) {
		span {
			&:after {
				font-family: "Material Icons";
				content: "\e5cf";
				float: right;
				font-size: 18px;
			}
		}
	}
	.accordion-header.collapsed {
		span {
			&:after {
				font-family: "Material Icons";
				content: "\e5ce";
				float: right;
				font-size: 18px;
			}
		}
	}
}

.image {
	width: 24px;
	height: 24px;
	overflow: hidden;
	border-radius: 50%;
	background: #efefef;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		vertical-align: top;
	}

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		font-weight: bold;
		height: 100%;
	}
}
</style>
