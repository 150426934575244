<template>
	<terms-of-use
		v-if="loginHelperVariables.loginStep == 'tou'"
		@agreed="processTou"
	></terms-of-use>
	<self-sign-in
		v-if="loginHelperVariables.loginStep == 'self-sign-in'"
		:courses="courses"
		:enrolledCourses="enrolledCourses"
		:credentials="credentials.value"
		@done="loginFromSelfSignIn"
		@back="loginHelperVariables.loginStep = 'cluster-discovery'"
	></self-sign-in>
	<div
		v-show="loginHelperVariables.loginStep == 'cluster-discovery'"
		class="authPage"
	>
		<div
			v-if="loginHelperVariables.loginStep == 'cluster-discovery'"
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<router-link :to="{ name: 'auth.login' }" class="text-dark d-flex">
				<span class="material-icons-outlined" aria-label="back">
					arrow_back
				</span>
			</router-link>

			<button
				class="btn btn-link"
				data-bs-toggle="modal"
				data-bs-target="#compatibility"
			>
				View Compatibility
			</button>
		</div>
		<div
			v-show="loginHelperVariables.loginStep == 'cluster-discovery'"
			:class="{
				'pb-104': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<div
				class="mx-auto pt-40"
				:class="{
					'w-100': helpers.layout.isMobile(),
					'w-50': !helpers.layout.isMobile(),
				}"
			>
				<div class="text-center">
					<h1 class="mt-0">Sign In</h1>

					<!-- <span
						class="
							pageIcons
							d-inline-flex
							align-items-center
							justify-content-center
						"
					>
						<span class="material-icons-outlined" aria-label=""> mail </span>
					</span> -->
				</div>
			</div>

			<!-- <div
				v-if="loginHelperVariables.loginStep == 'cluster-discovery'"
				class="mx-auto pt-40"
				:class="{
					'w-100': helpers.layout.isMobile(),
					'w-50': !helpers.layout.isMobile(),
				}"
			>
				<div
					:class="{
						'px-3': helpers.layout.isMobile(),
					}"
				>
					<div class="mb-3">
						<div class="w-100 btn btn-outline-light d-flex align-items-center w-icon">
							<span
								class="
									round-icon
									d-flex
									align-items-center
									justify-content-center
								"
							>
								<img src="/img/google.png" />
							</span>
							<span class="button-with-left-icon">Sign-in with Google</span>
						</div>
					</div>

					<div>
						<button
							class="w-100 btn btn-outline-light d-flex align-items-center w-icon"
						>
							<span
								class="
									round-icon
									d-flex
									align-items-center
									justify-content-center
								"
							>
								<img src="/img/microsoft-icon.png" />
							</span>
							<span class="button-with-left-icon">Sign-in with Microsoft</span>
						</button>
					</div>
				</div>
			</div> -->

			<kr-form
				ref="form"
				v-slot="{ meta, rules, data, isSubmitting }"
				:options="loginFormOptions"
				class="needs-validation"
				autocomplete="false"
				novalidate
			>
				<div
					class="mx-auto pt-40"
					:class="{
						'w-100': helpers.layout.isMobile(),
						'w-50 min-h-100': !helpers.layout.isMobile(),
					}"
				>
					<div
						:class="{
							'px-3': helpers.layout.isMobile(),
						}"
					>
						<div v-if="!loginHelperVariables.is2FAEnabled">
							<div
								v-if="loginHelperVariables.loginStep == 'cluster-discovery'"
								class="mb-4"
							>
								<kr-form-i
									v-slot="{ field, errors }"
									v-model="data.model.identity"
									name="identity"
									:rules="rules('identity')"
									tooltip-message="This is the valid email address associated with your institution."
								>
									<label for="identity_input" class="form-label">
										<span>
											Email<span class="text-danger" aria-hidden="true">*</span>
										</span>

										<!-- <span
											:class="{ isMobile: helpers.layout.isMobile() }"
											tooltip="This is the valid email address associated with your institution."
											class="material-icons-outlined"
											aria-hidden="true"
										>
											info
										</span> -->
									</label>
									<input
										id="identity_input"
										type="email"
										class="form-control"
										:class="{ 'is-invalid': errors[0] }"
										v-bind="field"
										aria-describedby="identity_error"
										aria-required="true"
										:aria-invalid="errors[0] ? true : false"
										@focus="updateOverallErrorMessage()"
									/>
								</kr-form-i>
							</div>
							<div
								v-if="
									loginHelperVariables.loginStep == 'cluster-discovery' ||
									loginHelperVariables.retypePassword
								"
								class="mb-4"
							>
								<kr-form-i
									v-slot="{ field, errors }"
									v-model="data.model.password"
									name="password"
									:rules="rules('password')"
									position="bottom"
									tooltip-message="Password must be at least 12 characters and contain a combination of uppercase and lowercase letters, numbers and symbols."
								>
									<label for="password_input" class="form-label">
										<span>
											Password<span class="text-danger" aria-hidden="true"
												>*</span
											>
										</span>
										<!-- <span
											:class="{ isMobile: helpers.layout.isMobile() }"
											tooltip="Please enter a unique password - It should be at least 12 characters and contain a combination of uppercase and lowercase letters, numbers, and symbols."
											class="material-icons-outlined"
											aria-hidden="true"
										>
											info
										</span> -->
									</label>

									<input
										id="password_input"
										:type="showPassword ? 'text' : 'password'"
										class="form-control position-relative"
										:class="{ 'is-invalid': errors[0] }"
										v-bind="field"
										aria-describedby="password_error"
										aria-required="true"
										:aria-invalid="errors[0] ? true : false"
										@focus="updateOverallErrorMessage()"
									/>
									<button
										class="
											btn
											cursor-pointer
											position-absolute
											top-33px
											end-33px
											p-0
											rounded
										"
										:aria-label="
											showPassword ? 'Hide Password' : 'Show Password'
										"
										@click="showPassword = !showPassword"
									>
										<span class="material-icons-outlined" aria-hidden="true">
											<template v-if="showPassword"> visibility_off </template>
											<template v-else> visibility </template>
										</span>
									</button>
								</kr-form-i>
								<router-link
									v-slot="{ navigate }"
									:to="{ name: 'auth.password.email' }"
									class="d-block mt-2"
								>
									Forgot Password
								</router-link>
							</div>
							<div
								v-if="
									loginHelperVariables.loginStep == 'cluster-discovery' &&
									overallErrorMessage
								"
								class="invalid-feedback mb-4"
								:class="{ 'd-block': overallErrorMessage }"
								role="alert"
								aria-atomic="true"
							>
								<div class="d-flex align-items-center">
									<span class="material-icons-outlined" aria-hidden="true">
										error_outline
									</span>
									<span> Incorrect email or password. Please try again. </span>
								</div>
							</div>
						</div>

						<div v-if="loginHelperVariables.loginStep == 'cluster-discovery'">
							<div
								class="d-flex align-items-center justify-content-between mb-4"
							>
								<div
									class="
										form-check
										checkbox-custom
										px-0
										d-flex
										align-items-center
									"
								>
									<input
										id="keep-me-signed-in"
										v-model="data.model.rememberMe"
										type="checkbox"
										class="form-check-input"
									/>
									<label
										class="form-check-label font-normal mb-0"
										for="keep-me-signed-in"
									>
										Keep me signed in
									</label>
								</div>
							</div>

							<div>
								<!-- <router-link :to="{ name: 'auth.login.trouble' }">
								Having trouble signing in?
							</router-link> -->

								<p class="d-inline-block">Need help signing in? See our</p>
								<a
									href="https://help.intedashboard.com/i-am-unable-to-login"
									target="_blank"
									class="fw-bold"
								>
									Help Guides
								</a>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="
						loginHelperVariables.loginStep == 'cluster-discovery' ||
						loginHelperVariables.retypePassword
					"
					:class="{
						'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
						'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
					}"
				>
					<div
						v-if="helpers.layout.isMobile()"
						class="custom-btn-gradient bg-gradient"
					/>
					<div
						v-if="route.query.from == 'reset'"
						class="alert alert-success text-white d-flex align-items-center"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<span class="material-icons-outlined me-1" aria-hidden="true">
							error_outline
						</span>
						Please login using your new password
					</div>
					<button
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="!meta.valid || !meta.dirty || isSubmitting"
						@click="tryLogin"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Sign In </template>
					</button>
				</div>
			</kr-form>
		</div>
	</div>
	<div v-if="loginHelperVariables.loginStep == 'role-choosing'">
		<login-roles
			:clusters="loginHelperVariables.clusters"
			@choose="processRoleChoosing"
			@suspended="suspendModal.show()"
			@logout="logout"
		></login-roles>
	</div>

	<div
		id="suspendModal"
		class="modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		tabindex="-1"
		role="dialog"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'd-flex align-items-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						class="btn btn-outline-light iconOnly"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-icons-outlined" aria-hidden="true">
							arrow_back
						</span>
					</button>
				</div>
				<div class="modal-body announcement-modal">
					<div class="header-icon mb-3">
						<span class="material-icons-outlined" aria-hidden="true">
							sentiment_dissatisfied
						</span>
					</div>

					<h1 class="mb-3">Your Account Has Been Suspended</h1>
				</div>
				<div class="modal-footer p-0 border-0">
					<div class="m-0 w-100">
						<button class="w-100 btn btn-primary" data-bs-dismiss="modal">
							Try Again
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		id="2faModal"
		class="modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		tabindex="-1"
		role="dialog"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'd-flex align-items-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						class="btn btn-outline-light iconOnly"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-icons-outlined"> arrow_back </span>
					</button>
				</div>
				<div class="modal-body announcement-modal">
					<div class="header-icon mb-3">
						<div class="w-100 text-center mb-4">
							<span
								class="
									pageIcons
									d-inline-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									lock
								</span>
							</span>
						</div>
					</div>

					<h2 class="mb-3 text-center">Almost there...</h2>

					<pin-code
						v-if="form"
						v-model="form.model.pin"
						@change="form.setFieldError('pin')"
					>
					</pin-code>
					<p v-if="form" class="text-center">
						A 6-digit one-time pin (OTP) has been sent via email to
						{{ form.model.identity }}. Enter it above to verify your identity
						and continue to your account.<br />
						Click
						<template v-if="!otpTimeout"
							><a href="#" @click.prevent="resend2FA"> here </a></template
						><template v-else>here</template> to resend OTP.
						<template v-if="otpResent"><br />OTP has been resent</template>
					</p>
				</div>

				<div class="modal-footer p-0 border-0">
					<div class="m-0 w-100">
						<div
							v-if="form && form.errors.pin"
							class="alert alert-danger text-white d-flex align-items-center"
							role="alert"
							aria-live="assertive"
							aria-atomic="true"
						>
							<span class="material-icons-outlined me-1" aria-hidden="true">
								error_outline
							</span>
							{{ form.errors.pin }}
						</div>
						<button
							v-if="form"
							class="w-100 btn btn-primary"
							:disabled="form.isSubmitting || form.model.pin.length != 6"
							@click="login2FA()"
						>
							<template v-if="form.isSubmitting">
								<span
									class="spinner-border spinner-border-sm me-8px"
									role="status"
									aria-hidden="true"
								></span>
								Loading...
							</template>
							<template v-else> Verify </template>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		id="compatibility"
		class="modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		tabindex="-1"
		role="dialog"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
		aria-hidden="true"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'd-flex align-items-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						class="btn btn-outline-light iconOnly"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-icons-outlined" aria-hidden="true">
							arrow_back
						</span>
					</button>
				</div>
				<div class="modal-body announcement-modal">
					<h1 class="mb-4">Compatibility</h1>
					<div class="mb-4">
						<h2 class="fw-bold mb-2 sub-header">Google Chrome</h2>
						<p class="fw-light">
							Windows - Windows 8.1 or above <br />
							Mac - macOS 10.15 Catalina or above <br />
							iOS - iOS 13 or above <br />
							Android - Android Marshmallow 6.0 or above
						</p>
					</div>
					<div class="mb-4">
						<h2 class="fw-bold mb-2 sub-header">Mozilla Firefox</h2>
						<p class="fw-light">
							Windows - Windows 8.1 or above <br />
							Mac - macOS 10.15 Catalina or above <br />
							iOS - iOS 13 or above <br />
							Android - Android Marshmallow 6.0 or above
						</p>
					</div>
					<div class="mb-4">
						<h2 class="fw-bold mb-2 sub-header">Safari</h2>
						<p class="fw-light">
							Mac - macOS 10.15 Catalina or above <br />
							iOS - iOS 13 or above
						</p>
					</div>
					<div>
						<h2 class="fw-bold mb-2 sub-header">Microsoft Edge</h2>
						<p class="fw-light">
							Windows - Windows 8.1 or above <br />
							Mac - macOS 10.15 Catalina or above <br />
							iOS - iOS 13 or above <br />
							Android - Android Marshmallow 6.0 or above
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref, reactive, inject, onMounted, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default {
	components: {
		"login-roles": require(`./partials/partial.roles`).default,
		"terms-of-use": require(`./terms-of-use`).default,
		"self-sign-in": require(`./partials/partial.choose-section-team`).default,
	},
	setup(props, context) {
		const auth = inject("auth");
		const $api = inject("$api");
		const _ = inject("_");
		const route = useRoute();
		const router = useRouter();
		const helpers = inject("helpers");

		const form = ref(null);
		let credentials = toRefs({});
		let courses = ref(null);
		let enrolledCourses = ref(null);
		let faModal = ref(null);
		let suspendModal = ref(null);
		let otpResent = ref(false);
		let otpTimeout = ref(false);
		let loginHelperVariables = reactive({
			loginStep: "", //default:cluster-discovery
			retypePassword: false,
			is2FAEnabled: false,
			clusters: [],
			chosenRole: {},
		});
		const overallErrorMessage = ref(false);
		const showPassword = ref(false);

		const processTou = () => {
			if (route.name == "auth.lti") {
				ltiDiscovery(
					route.query.api_url,
					route.params.otp,
					route.query.agreement_date,
					route.query.lti1p3,
					route.query.courseUuid
				);
			}
		};
		const logout = () => {
			form.value.setFieldValue("password", "");
			loginHelperVariables.loginStep = "cluster-discovery";
			loginHelperVariables.retypePassword = false;
			loginHelperVariables.is2FAEnabled = false;
			loginHelperVariables.clusters = [];
		};
		const loginFormOptions = ref({
			fields: {
				identity: { value: "", rules: "email|required" },
				password: { rules: "password|required" },
				pin: { rules: "required" },
				loginToken: { value: "", rules: "required" },
				apiURL: { value: "", rules: "required" },
				rememberMe: { value: true, rules: "" },
				agree: { value: true, rules: "required" },
			},
		});
		const processClusterFormat = (response) => {
			let i = 0,
				j = 0,
				k = 0;
			let cluster = {};
			loginHelperVariables.clusters = [];
			let result = [],
				orgName = "";
			let users = { student: false, teacher: false, superuser: false };
			let temp = [];
			for (i = 0; i < response.data.length; i++) {
				cluster = response.data[i];
				loginHelperVariables.clusters.push({
					url: cluster.url,
					name: cluster.name,
					organisations: [],
				});
				result = _.get(cluster.data, "result", []);
				if (result.length == 1 && response.data.length == 1) {
					//login
					login(cluster.url, result[0].loginToken);
					return;
				}
				for (j = 0; j < result.length; j++) {
					orgName = result[j].organisation.organisationName;
					if (
						_.findIndex(
							loginHelperVariables.clusters[i].organisations,
							function (o) {
								return orgName == o.organisationName;
							}
						) == -1
					) {
						users = {
							student: false,
							teacher: false,
							superAdmin: false,
							superuser: false,
						};
						temp = _.filter(result, function (o) {
							return o.organisation.organisationName == orgName;
						});
						//check lti
						for (k = 0; k < temp.length; k++) {
							temp[k].lti1p3 = cluster.lti1p3;
							if (temp[k].role == "Student") {
								users.student = temp[k];
							} else if (temp[k].role == "Teacher") {
								users.teacher = temp[k];
							} else if (temp[k].role == "Super Admin") {
								users.superAdmin = temp[k];
							} else if (temp[k].role == "Superuser") {
								users.superuser = temp[k];
							}
						}
						loginHelperVariables.clusters[i].organisations.push({
							...result[j].organisation,
							users: users,
						});
					}
				}
			}
			loginHelperVariables.loginStep = "role-choosing";
		};
		const ltiDiscovery = (
			apiURL,
			otp,
			agreementDate = "",
			lti1p3 = false,
			courseUuid = ""
		) => {
			lti1p3 = lti1p3 == "true" ? true : false;
			$api.auth
				.loginLti(otp, agreementDate, lti1p3, courseUuid, apiURL)
				.then(function (response) {
					if (_.get(response, "data.data.token", false)) {
						credentials.value = response.data;
						if (response.data.meta) {
							//student
							courses.value = response.data.meta.courses;
							if (lti1p3) {
								enrolledCourses.value = response.data.meta.enrolledCourses;
							} else {
								enrolledCourses.value = [];
							}
							if (
								!lti1p3 ||
								(response.data.meta.courses.length == 0 &&
									response.data.meta.enrolledCourses.length > 0 &&
									lti1p3)
							) {
								auth.login(response.data);
							} else {
								credentials.value.lti1p3 = lti1p3;
								loginHelperVariables.loginStep = "self-sign-in";
							}
						} else {
							//teacher
							auth.login(response.data);
						}
					} else {
						let a = {
							data: [
								{
									name: "lti",
									url: response.data.api_url,
									data: response.data,
									lti1p3: lti1p3,
								},
							],
						};
						processClusterFormat(a);
					}
				})
				.catch(function (errors) {
					if (lti1p3) {
						router.push({
							name: "auth.errors.403",
							query: { message: errors.response.data.message },
						});
					} else {
						loginHelperVariables.loginStep = "cluster-discovery";
					}
				});
		};
		const loginFromSelfSignIn = () => {
			auth.login(credentials.value);
		};
		const login = (apiURL, otp) => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.login(
						{ loginToken: otp, remember: form.value.values.rememberMe },
						apiURL
					)
					.then(function (response) {
						if (!response.data.is2FAEnabled) {
							auth.login(response.data);
						} else {
							loginHelperVariables.is2FAEnabled = response.data.is2FAEnabled;
							form.value.setFieldValue("loginToken", response.data.loginToken);
							form.value.setFieldValue("apiURL", apiURL);
							faModal.value.show();
						}
					});
			})();
		};
		const processRoleChoosing = (data) => {
			if (data.meta && data.role == "Student") {
				//from lti redirect to course choosing
				courses.value = data.meta.courses;
				//lti1p3
				enrolledCourses.value = data.meta.enrolledCourses;
				credentials.value = { data: data.auth, lti1p3: data.lti1p3 };
				if (
					data.lti1p3 &&
					enrolledCourses.value.length > 0 &&
					courses.value.length == 0
				) {
					auth.login(credentials.value);
				} else {
					loginHelperVariables.loginStep = "self-sign-in";
				}
			} else {
				loginHelperVariables.chosenRole = data;
				tryLogin();
			}
		};
		const tryLogin = () => {
			if (loginHelperVariables.loginStep == "cluster-discovery") {
				form.value.handleSubmit((values) => {
					return $api.auth
						.verify({
							identity: values.identity,
							password: values.password,
						})
						.then(function (response) {
							processClusterFormat(response);
						})
						.catch(function (errors) {
							if (_.get(errors, "response.status") === 403) {
								suspendModal.value.show();
							} else if (_.get(errors, "response.status") === 423) {
								form.value.setErrors({
									password: [_.get(errors, "response.data.message")],
								});
							} else {
								overallErrorMessage.value = true;
								form.value.setErrors({
									identity: ["show outline only"],
									password: ["show outline only"],
								});
							}
						});
				})();
			}
			if (loginHelperVariables.loginStep == "role-choosing") {
				login(
					loginHelperVariables.chosenRole.clusterUrl,
					loginHelperVariables.chosenRole.loginToken
				);
			}
		};
		const login2FA = () => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.login2FA(
						{
							loginToken: form.value.values.loginToken,
							pin: form.value.values.pin,
							identity: form.value.values.identity,
							remember: form.value.values.rememberMe,
						},
						form.value.values.apiURL
					)
					.then(function (response) {
						auth.login(response.data);
					})
					.catch(function (errors) {
						form.value.setErrors({
							pin: [errors.response.data.message],
						});
					});
			})();
		};
		const resend2FA = () => {
			otpTimeout.value = true;
			form.value.handleSubmit((values) => {
				return $api.auth
					.resend2FA(
						{ loginToken: form.value.values.loginToken },
						form.value.values.apiURL
					)
					.then(function (response) {
						otpResent.value = true;
						setTimeout(function () {
							otpTimeout.value = false;
						}, 15000);
					})
					.catch(function (errors) {
						otpTimeout.value = false;
					});
			})();
		};
		const switchRole = () => {
			$api.auth
				.switchRole(auth.credential().accessToken, auth.credential().apiUrl)
				.then(function (response) {
					auth.login(response.data);
				})
				.catch(function (errors) {
					router.push({ name: "auth.login" });
				});
		};

		const updateOverallErrorMessage = () => {
			if (overallErrorMessage.value == true) {
				form.value.setErrors({
					identity: [],
					password: [],
				});
				overallErrorMessage.value = false;
			}
		};

		onMounted(() => {
			faModal.value = new Modal(document.getElementById("2faModal"));
			suspendModal.value = new Modal(document.getElementById("suspendModal"));
			if (route.name == "auth.lti") {
				if (route.params.otp && route.query.api_url) {
					if (route.query.agreement_date) {
						ltiDiscovery(
							route.query.api_url,
							route.params.otp,
							route.query.agreement_date,
							route.query.lti1p3,
							route.query.courseUuid
						);
					} else {
						loginHelperVariables.loginStep = "tou";
					}
				}
			} else if (route.name == "auth.switch") {
				if (auth.isLoggedIn()) {
					loginHelperVariables.loginStep = "switch";
					switchRole();
				}
			} else {
				loginHelperVariables.loginStep = "cluster-discovery";
			}
		});

		return {
			auth,
			route,
			helpers,
			faModal,
			suspendModal,
			form,
			loginFormOptions,
			loginHelperVariables,
			processTou,
			tryLogin,
			processRoleChoosing,
			login,
			logout,
			login2FA,
			resend2FA,
			otpResent,
			otpTimeout,
			courses, //for lti
			enrolledCourses, //for lti
			credentials, //for lti
			loginFromSelfSignIn, //for lti
			overallErrorMessage,
			showPassword,
			updateOverallErrorMessage,
		};
	},
};
</script>
<style lang="scss" scoped>
.contentBody {
	height: calc(100% - 120px - 134px);
}
</style>
